// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-pages-gan-gan-jsx": () => import("./../../../src/components/Pages/Gan/Gan.jsx" /* webpackChunkName: "component---src-components-pages-gan-gan-jsx" */),
  "component---src-components-pages-weekly-update-weekly-update-jsx": () => import("./../../../src/components/Pages/WeeklyUpdate/weekly-update.jsx" /* webpackChunkName: "component---src-components-pages-weekly-update-weekly-update-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-democratic-center-jsx": () => import("./../../../src/pages/democratic-center.jsx" /* webpackChunkName: "component---src-pages-democratic-center-jsx" */),
  "component---src-pages-democraticeducation-jsx": () => import("./../../../src/pages/democraticeducation.jsx" /* webpackChunkName: "component---src-pages-democraticeducation-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-signup-jsx": () => import("./../../../src/pages/signup.jsx" /* webpackChunkName: "component---src-pages-signup-jsx" */),
  "component---src-pages-work-in-gan-jsx": () => import("./../../../src/pages/work-in-gan.jsx" /* webpackChunkName: "component---src-pages-work-in-gan-jsx" */),
  "component---src-templates-gan-section-template-jsx": () => import("./../../../src/templates/GanSectionTemplate.jsx" /* webpackChunkName: "component---src-templates-gan-section-template-jsx" */),
  "component---src-templates-weekly-update-post-template-jsx": () => import("./../../../src/templates/WeeklyUpdatePostTemplate.jsx" /* webpackChunkName: "component---src-templates-weekly-update-post-template-jsx" */)
}

